<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="selected">
        <v-list-item
          v-for="locale in this.enabledLocales"
          :key="locale"
          @click="select(locale)"
        >
          <v-list-item-title>{{ languages[locale] }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      languages: {
        de: "Deutsch",
        en: "English",
        bg: "Български"
      },
      selected: null
    };
  },
  methods: {
    select(locale) {
      this.$i18n.locale = locale;
      store.dispatch("common/updatePreferredLocale", this.$i18n.locale);
    }
  },
  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.selected = this.enabledLocales.indexOf(newLocale);
      },
      { immediate: true }
    );
  },
  created() {
    this.enabledLocales = process.env.VUE_APP_I18N_LOCALES.split(",");
  }
};
</script>
