import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import axiosRetry from "axios-retry";
import { common } from "@/store/common";
import { pictures } from "@/store/pictures";
import { likes } from "@/store/likes";
import { messaging } from "@/store/messaging";

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND;

axiosRetry(axios, {
  retries: 2,
  retryDelay: retryCount => {
    return retryCount * 2000; // time interval between retries
  }
});

export default new Vuex.Store({
  modules: {
    common: common,
    pictures: pictures,
    likes: likes,
    messaging: messaging
  }
});
