<template>
  <div>
    <v-app id="app" v-if="vuetifyLoaded">
      <v-navigation-drawer
        v-if="currentUser"
        v-model="drawer"
        app
        clipped
        :permanent="$vuetify.breakpoint.mdAndUp && !drawer"
        :mini-variant="$vuetify.breakpoint.mdAndUp && !drawer"
      >
        <Navigation></Navigation>
      </v-navigation-drawer>

      <v-app-bar v-if="currentUser" app color="primary" dark clipped-left>
        <v-app-bar-nav-icon v-if="currentUser" @click.stop="drawer = !drawer" />
        <v-avatar tile size="32" class="ml-1 mr-1">
          <img :src="this.appLogo" alt="logo" />
        </v-avatar>
        <router-link to="/" class="toolbar-title">
          <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
        </router-link>

        <v-spacer></v-spacer>

        <like-updates v-if="currentUser" />

        <chat-updates v-if="currentUser" />

        <div>
          <locale-switch v-if="$vuetify.breakpoint.mdAndUp" />
        </div>

        <v-menu
          v-if="$vuetify.breakpoint.mdAndUp"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Dark mode</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox v-model="darkMode" color="primary"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>

        <additional-menu />
      </v-app-bar>

      <v-main>
        <v-container
          fluid
          :class="{
            'overflow-y-auto': true,
            smHeight: $vuetify.breakpoint.smAndDown,
            mdHeight: $vuetify.breakpoint.mdAndUp
          }"
          ref="mainContainer"
          id="mainContainer"
        >
          <router-view />
        </v-container>
      </v-main>

      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.type"
        timeout="5000"
        top
        right
      >
        {{ snackbar.message }}
        <v-btn dark text @click="hideSnackbar()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>

      <v-overlay :value="loading">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-overlay>
    </v-app>
    <router-view v-else-if="nonVuetifyPage(this.$route.path)" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "./store";
import AdditionalMenu from "@/components/AdditionalMenu";
import LocaleSwitch from "@/components/LocaleSwitch";

export default {
  data: () => ({
    vuetifyLoaded: false,
    drawer: false,
    messages: 3,
    darkMode: false,
    darkModeTooltip: false
  }),
  components: {
    LikeUpdates: () => import("@/components/LikeUpdates"),
    ChatUpdates: () => import("@/components/ChatUpdates"),
    AdditionalMenu,
    Navigation: () => import("./components/Navigation.vue"),
    LocaleSwitch
  },
  computed: {
    ...mapState("common", ["currentUser", "myProfile", "snackbar", "loading"])
  },
  methods: {
    nonVuetifyPage(path) {
      const whitelist = [
        "/",
        "/loader",
        "/terms",
        "/privacy",
        "/impressum",
        "/auth/action"
      ];
      return whitelist.includes(path);
    },
    initLocation() {
      this.$getLocation().then(coordinates => {
        store.dispatch("common/updateLocation", {
          lat: coordinates.lat,
          lng: coordinates.lng
        });
      });
    },
    initMessagingToken() {
      this.$firebase.messaging
        .getToken({
          vapidKey: this.vapidKey
        })
        .then(currentToken => {
          if (currentToken) {
            return store.dispatch("messaging/sendMessagingToken", currentToken);
          } else {
            console.log("No Instance ID token available.");
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
    hideSnackbar() {
      store.dispatch("common/showError", null);
    },
    enableAds(client) {
      const adSense = document.createElement("script");
      adSense.setAttribute(
        "src",
        `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`
      );
      adSense.setAttribute("async", true);
      adSense.setAttribute("crossorigin", "anonymous");
      document.head.appendChild(adSense);
    }
  },
  watch: {
    currentUser(user) {
      if (user) {
        store.dispatch("common/fetchMyProfile", this.$i18n.locale);
      }
    },
    myProfile(profile) {
      if (profile) {
        this.initLocation();
        this.initMessagingToken();

        if (profile.preferredLocale) {
          this.$i18n.locale = profile.preferredLocale;
        }

        if (
          profile.emailVerified ||
          this.currentUser.providerData[0].providerId === "facebook.com"
        ) {
          if (this.profileIncomplete(profile) && this.$route.path !== "/init") {
            return this.$router.push("/init");
          }
          if (this.$route.path === "/") {
            return this.$router.push("/discover");
          }
        } else {
          if (this.$route.path !== "/verify") {
            return this.$router.push("/verify");
          }
        }
      }
    },
    $route(to) {
      if (!this.vuetifyLoaded && !this.nonVuetifyPage(to.path)) {
        return this.$router.push("/loader?target=" + to.path);
      }
    },
    darkMode: function(value) {
      this.$vuetify.theme.dark = value;
    }
  },
  mounted() {
    if (this.$vuetify) {
      this.vuetifyLoaded = true;

      if (this.adSenseClient) {
        this.enableAds(this.adSenseClient);
      }
    }
  },
  created() {
    this.appTitle = process.env.VUE_APP_TITLE;
    this.appLogo = process.env.VUE_APP_LOGO;
    this.vapidKey = process.env.VUE_APP_VAPID_KEY;
    this.adSenseClient = process.env.VUE_APP_AD_SENSE_CLIENT;
  }
};
</script>

<style>
.toolbar-title {
  color: inherit !important;
  text-decoration: inherit;
}
.smHeight {
  height: calc(100vh - 56px);
}
.mdHeight {
  height: calc(100vh - 64px);
}
</style>
