import axios from "axios";

export const pictures = {
  namespaced: true,
  state: () => ({
    myPictures: [],
    userPictures: {}
  }),
  mutations: {
    setUserPictures(state, val) {
      if (val) {
        state.userPictures = { ...state.userPictures, ...val };
      } else {
        state.userPictures = {};
      }
    },
    setMyPictures(state, val) {
      if (val) {
        state.myPictures = val;
      } else {
        state.myPictures = [];
      }
    }
  },
  actions: {
    clearData({ commit }) {
      commit("setMyPictures", []);
      commit("setUserPictures", {});
    },
    fetchUserPictures({ commit, dispatch }, userId) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .get(`users/${userId}/pictures`)
          .then(response => {
            commit("setUserPictures", { [userId]: response.data });
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchMyPictures({ commit, rootState, dispatch }) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .get(`users/${rootState.common.currentUser.uid}/pictures`)
          .then(response => {
            commit("setMyPictures", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    addPicture({ commit, state, rootState, dispatch }, formData) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(
            `users/${rootState.common.currentUser.uid}/pictures`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(response => {
            let pictures = [...state.myPictures];
            pictures.push(response.data);
            commit("setMyPictures", pictures);
          })
          .catch(e => {
            if (e.response && e.response.data.info) {
              throw new Error(e.response.data.info);
            } else {
              console.log(e);
              this.dispatch("common/showError", e.message, { root: true });
            }
          });
      });
    },
    setMainPicture({ commit, rootState, dispatch }, picture) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .put(
            `users/${rootState.common.currentUser.uid}/main-picture/${picture.id}`
          )
          .then(response => {
            commit("common/setMyProfile", response.data, { root: true });
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    removePicture({ commit, state, rootState, dispatch }, picture) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .delete(
            `users/${rootState.common.currentUser.uid}/pictures/${picture.id}`
          )
          .then(() => {
            let pictures = state.myPictures.filter(item => {
              return item.id !== picture.id;
            });
            commit("setMyPictures", pictures);
          })
          .catch(e => {
            console.log(e);
            this.dispatch("common/showError", e.message, { root: true });
          });
      });
    }
  },
  getters: {}
};
