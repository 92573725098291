import axios from "axios";

export const likes = {
  namespaced: true,
  state: () => ({
    likesReceived: null,
    likesSent: [],
    matches: [],
    likeUpdates: []
  }),
  mutations: {
    setLikesReceived(state, val) {
      if (val) {
        state.likesReceived = val;
      } else {
        state.likesReceived = null;
      }
    },
    setLikesSent(state, val) {
      if (val) {
        state.likesSent = val;
      } else {
        state.likesSent = [];
      }
    },
    setMatches(state, val) {
      if (val) {
        state.matches = val;
      } else {
        state.matches = [];
      }
    },
    setLikeUpdates(state, val) {
      if (val) {
        state.likeUpdates = val;
      } else {
        state.likeUpdates = [];
      }
    }
  },
  actions: {
    clearData({ commit }) {
      commit("setLikesReceived", null);
      commit("setLikesSent", []);
      commit("setMatches", []);
      commit("setLikeUpdates", []);
    },
    fetchLikesReceived({ commit, rootState, dispatch }) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(`likes/received/${rootState.common.currentUser.uid}`)
          .then(response => {
            commit("setLikesReceived", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchLikesSent({ commit, rootState, dispatch }) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(`likes/sent/${rootState.common.currentUser.uid}`)
          .then(response => {
            commit("setLikesSent", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchMatches({ commit, rootState, dispatch }) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(`likes/matches/${rootState.common.currentUser.uid}`)
          .then(response => {
            commit("setMatches", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchLikeUpdates({ commit, rootState, dispatch }) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(`likes/updates/${rootState.common.currentUser.uid}`)
          .then(response => {
            commit("setLikeUpdates", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    confirmLike({ commit, state, rootState, dispatch }, likeRefId) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .put(
            `likes/received/confirm/${rootState.common.currentUser.uid}`,
            likeRefId,
            {
              headers: {
                "Content-Type": "text/plain"
              }
            }
          )
          .then(() => {
            const likeUpdates = [...state.likeUpdates];
            state.likeUpdates.forEach((value, index) => {
              if (value.id === likeRefId) {
                likeUpdates.splice(index, 1);
              }
            });
            commit("setLikeUpdates", likeUpdates);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    sendLike({ commit, state, rootState, dispatch }, targetUserId) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(`likes/${rootState.common.currentUser.uid}/${targetUserId}`)
          .then(response => {
            let likesSent = [...state.likesSent];
            likesSent.push(response.data);
            commit("setLikesSent", likesSent);
          });
      });
    }
  }
};
