<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item to="/subscriptions">
          <v-list-item-title>{{ $t("subscriptions") }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item to="/impressum">
          <v-list-item-title>{{ $t("impressum") }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/terms">
          <v-list-item-title>{{ $t("terms") }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/privacy">
          <v-list-item-title>{{ $t("privacy") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "AdditionalMenu",
  components: {}
};
</script>

<i18n>
{
  "en": {
    "subscriptions": "Subscriptions",
    "impressum": "Impressum",
    "terms": "Terms of Service",
    "privacy": "Privacy"
  },
  "de": {
    "subscriptions": "Abonnements",
    "impressum": "Impressum",
    "terms": "AGB",
    "privacy": "Datenschutz"
  },
  "bg": {
    "subscriptions": "Абонаменти",
    "impressum": "За контакт",
    "terms": "Условия за ползване",
    "privacy": "Лични данни"
  }
}
</i18n>
