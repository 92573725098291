import axios from "axios";

export const common = {
  namespaced: true,
  state: () => ({
    currentUser: null,
    myProfile: null,
    lastLocationUpdate: null,
    userProfiles: {},
    suggestedUsers: [],
    suggestedUserOrder: null,
    suggestedUsersEndReached: false,
    snackbar: {},
    loading: false
  }),
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    setMyProfile(state, val) {
      state.myProfile = val;
    },
    setLastLocationUpdate(state, val) {
      state.location = val;
    },
    setUserProfile(state, val) {
      if (val) {
        state.userProfiles = { ...state.userProfiles, ...val };
      } else {
        state.userProfiles = {};
      }
    },
    setUsers(state, val) {
      if (val) {
        state.suggestedUsers = val;
      } else {
        state.suggestedUsers = [];
      }
    },
    setUserOrder(state, val) {
      if (val) {
        state.suggestedUserOrder = val;
      } else {
        state.suggestedUserOrder = null;
      }
    },
    setUserEndReached(state, val) {
      if (val) {
        state.suggestedUsersEndReached = val;
      } else {
        state.suggestedUsersEndReached = false;
      }
    },
    setSnackbar(state, val) {
      if (val) {
        state.snackbar = val;
      } else {
        state.snackbar = {};
      }
    },
    setLoading(state, val) {
      if (val) {
        state.loading = val;
      } else {
        state.loading = false;
      }
    }
  },
  actions: {
    clearData({ commit }) {
      commit("setCurrentUser", null);
      commit("setMyProfile", null);
      commit("setUsers", []);
      commit("setUserOrder", null);
      commit("setUserEndReached", false);
      commit("setSnackbar", {});
      commit("setLoading", false);
    },
    fetchIdToken({ commit, dispatch }, user) {
      return user
        .getIdToken(true)
        .then(idToken => {
          const encodedToken = btoa(idToken);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${encodedToken}`;
          commit("setCurrentUser", user);
        })
        .catch(e => {
          console.log(e);
          dispatch("showError", e.message);
        });
    },
    withIdToken({ state }) {
      return state.currentUser.getIdToken().then(idToken => {
        const encodedToken = btoa(idToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${encodedToken}`;
      });
    },
    fetchMyProfile({ commit, state, dispatch }, locale) {
      commit("setLoading", true);
      return dispatch("withIdToken").then(() => {
        return axios
          .get(`users/${state.currentUser.uid}`)
          .then(response => {
            commit("setMyProfile", response.data);
            commit("setLoading", false);
            return response.data;
          })
          .catch(e => {
            if (e.response.status === 404) {
              let newProfile = {
                id: state.currentUser.uid,
                email: state.currentUser.email,
                displayName: state.currentUser.displayName
              };

              if (locale) {
                newProfile.preferredLocale = locale;
              }

              if (
                state.currentUser.providerData[0].providerId === "facebook.com"
              ) {
                newProfile.facebookId = state.currentUser.providerData[0].uid;
              }

              return axios
                .post(`users/${state.currentUser.uid}`, newProfile)
                .then(response => {
                  commit("setMyProfile", response.data);
                  commit("setLoading", false);
                  return response.data;
                })
                .catch(e => {
                  console.log(e);
                  dispatch("showError", e.message);
                  commit("setLoading", false);
                });
            } else {
              console.log(e);
              dispatch("showError", e.message);
            }
          });
      });
    },
    updatePreferredLocale({ state, dispatch }, locale) {
      dispatch("withIdToken").then(() => {
        axios
          .put(`users/${state.currentUser.uid}/locale`, locale, {
            headers: {
              "Content-Type": "text/plain"
            }
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    updateLocation({ commit, state, dispatch }, location) {
      dispatch("withIdToken").then(() => {
        axios
          .put(`users/${state.currentUser.uid}/location`, location)
          .then(response => {
            if (response.status === 200) {
              commit("setLastLocationUpdate", Date.now());
            }
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchUserProfile({ commit, state, dispatch }, userId) {
      commit("setLoading", true);
      dispatch("withIdToken").then(() => {
        axios
          .get(`users/${state.currentUser.uid}/ref/${userId}`)
          .then(response => {
            commit("setUserProfile", { [userId]: response.data });
            commit("setLoading", false);
          })
          .catch(e => {
            console.log(e);
            dispatch("showError", e.message);
          });
      });
    },
    updateProfile({ commit, state, dispatch }, userProfile) {
      return dispatch("withIdToken").then(() => {
        return axios
          .put(`users/${state.currentUser.uid}`, userProfile)
          .then(response => {
            commit("setMyProfile", response.data);
            return response.data;
          })
          .catch(e => {
            console.log(e);
            dispatch("showError", e.message);
          });
      });
    },
    fetchSuggestedUsers({ commit, state, dispatch }, { orderBy, afterId }) {
      let queryString = `?orderBy=${orderBy}&pageSize=12`;
      if (afterId) {
        queryString += `&afterId=${encodeURIComponent(afterId)}`;
      }

      return dispatch("withIdToken").then(() => {
        return axios
          .get(`users/${state.currentUser.uid}/suggestions${queryString}`)
          .then(response => {
            if (orderBy === this.suggestedUserOrder && afterId) {
              commit("setUsers", state.suggestedUsers.concat(response.data));
            } else {
              this.suggestedUserOrder = orderBy;
              commit("setUsers", response.data);
            }
            return response.data.length;
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchCities({ dispatch }, { countryId, input, locale }) {
      return dispatch("withIdToken").then(() => {
        return axios
          .get(`cities/predict/${countryId}?input=${input}&locale=${locale}`)
          .then(response => {
            return response.data;
          });
      });
    },
    fetchCityDetails({ dispatch }, { cityId, locale }) {
      return dispatch("withIdToken").then(() => {
        return axios
          .get(`cities/details/${cityId}?locale=${locale}`)
          .then(response => {
            return response.data;
          });
      });
    },
    fetchSubscriptionTypes({ dispatch }) {
      return dispatch("withIdToken").then(() => {
        return axios.get("subscription-types/list").then(response => {
          return response.data;
        });
      });
    },
    deleteMyProfile({ state, dispatch }) {
      return dispatch("withIdToken").then(() => {
        return axios.delete(`users/${state.currentUser.uid}`);
      });
    },
    showError({ commit }, val) {
      if (val) {
        commit("setSnackbar", {
          visible: true,
          type: "error",
          message: val
        });
      } else {
        commit("setSnackbar", {
          visible: false
        });
      }
    }
  }
};
