var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vuetifyLoaded)?_c('v-app',{attrs:{"id":"app"}},[(_vm.currentUser)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","permanent":_vm.$vuetify.breakpoint.mdAndUp && !_vm.drawer,"mini-variant":_vm.$vuetify.breakpoint.mdAndUp && !_vm.drawer},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('Navigation')],1):_vm._e(),(_vm.currentUser)?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","clipped-left":""}},[(_vm.currentUser)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-avatar',{staticClass:"ml-1 mr-1",attrs:{"tile":"","size":"32"}},[_c('img',{attrs:{"src":this.appLogo,"alt":"logo"}})]),_c('router-link',{staticClass:"toolbar-title",attrs:{"to":"/"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.appTitle))])],1),_c('v-spacer'),(_vm.currentUser)?_c('like-updates'):_vm._e(),(_vm.currentUser)?_c('chat-updates'):_vm._e(),_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('locale-switch'):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)]}}],null,false,2114143414)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dark mode")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}})],1)],1)],1)],1):_vm._e(),_c('additional-menu')],1):_vm._e(),_c('v-main',[_c('v-container',{ref:"mainContainer",class:{
          'overflow-y-auto': true,
          smHeight: _vm.$vuetify.breakpoint.smAndDown,
          mdHeight: _vm.$vuetify.breakpoint.mdAndUp
        },attrs:{"fluid":"","id":"mainContainer"}},[_c('router-view')],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.type,"timeout":"5000","top":"","right":""},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" "),_c('v-btn',{attrs:{"dark":"","text":"","icon":""},on:{"click":function($event){return _vm.hideSnackbar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(_vm.nonVuetifyPage(this.$route.path))?_c('router-view'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }