import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";
import VueGeolocation from "vue-browser-geolocation";
const firebase = require("./firebaseConfig.js");

Vue.config.productionTip = false;

Vue.prototype.$firebase = firebase;

const swListener = new BroadcastChannel(`${process.env.VUE_APP_TITLE}App`);

Vue.mixin({
  methods: {
    profileIncomplete(profile) {
      return !profile.gender || !profile.targetGender || !profile.birthday;
    },
    getFriend(friendship) {
      let deletedFriend = null;
      if (friendship.friends[0].deletedId) {
        deletedFriend = friendship.friends[0];
      } else if (friendship.friends[1].deletedId) {
        deletedFriend = friendship.friends[1];
      }
      if (deletedFriend) {
        return {
          id: deletedFriend.deletedId,
          displayName: "[Deleted User]",
          mainPicture: null
        };
      }
      return friendship.friends[0].id === this.currentUser.uid
        ? friendship.friends[1]
        : friendship.friends[0];
    },
    getMainPicture(profile) {
      if (profile.mainPicture) {
        return profile.mainPicture;
      } else if (profile.useFacebookProfilePicture) {
        return this.getFacebookProfilePicture(profile);
      }
      return null;
    },
    getFacebookProfilePicture(profile) {
      const facebookToken = localStorage.getItem("facebookToken");
      return {
        urlSmall: `https://graph.facebook.com/${profile.facebookId}/picture?type=square&access_token=${facebookToken}`,
        urlLarge: `https://graph.facebook.com/${profile.facebookId}/picture?type=large&access_token=${facebookToken}`
      };
    }
  }
});

Vue.use(VueGeolocation);

// handle page reloads

firebase.auth.onAuthStateChanged(user => {
  if (!window.vueApp) {
    window.vueApp = new Vue({
      el: "#app",
      router,
      store,
      i18n,
      render: h => h(App)
    });
  }

  if (user) {
    store.dispatch("common/fetchIdToken", user).then(() => {
      firebase.messaging.onMessage(payload => {
        return store.commit("messaging/setPushNotification", payload);
      });

      swListener.onmessage = function(e) {
        return store.commit("messaging/setPushNotification", e.data);
      };
    });
  }
});
