import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  return opt.countryCodeOnly
    ? navigatorLocale.trim().split(/[-_]/)[0]
    : navigatorLocale.trim();
}

function supportedLocalesInclude(locale) {
  return process.env.VUE_APP_I18N_LOCALES.split(",").includes(locale);
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_DEFAULT_LOCALE || "en";
  }
}

export default new VueI18n({
  locale: getStartingLocale()
});
