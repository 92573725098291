import axios from "axios";

export const messaging = {
  namespaced: true,
  state: () => ({
    friendships: null,
    friendshipUpdates: [],
    cachedFriendship: null,
    messages: [],
    pushNotification: null,
    messagingTokenId: null
  }),
  mutations: {
    setFriendships(state, val) {
      if (val) {
        state.friendships = val;
      } else {
        state.friendships = null;
      }
    },
    setFriendshipUpdates(state, val) {
      if (val) {
        state.friendshipUpdates = val;
      } else {
        state.friendshipUpdates = [];
      }
    },
    setCachedFriendship(state, val) {
      state.cachedFriendship = val;
    },
    setMessages(state, val) {
      if (val) {
        state.messages = val;
      } else {
        state.messages = [];
      }
    },
    setPushNotification(state, val) {
      state.pushNotification = val;
    },
    setMessagingTokenId(state, val) {
      state.messagingTokenId = val;
    }
  },
  actions: {
    clearData({ commit }) {
      commit("setFriendships", null);
      commit("setFriendshipUpdates", []);
      commit("setMessages", []);
      commit("setMessagingTokenId", null);
    },
    fetchConversations({ commit, rootState, dispatch }) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .get(`friendships/${rootState.common.currentUser.uid}`)
          .then(response => {
            commit("setFriendships", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchFriendshipUpdates({ commit, rootState, dispatch }) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        axios
          .get(`friendships/${rootState.common.currentUser.uid}?unread=true`)
          .then(response => {
            commit("setFriendshipUpdates", response.data);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    fetchMessages(
      { commit, state, rootState, dispatch },
      { friendId, beforeId }
    ) {
      let queryString = "";
      if (beforeId) {
        queryString += `?beforeId=${beforeId}`;
      }

      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(
            `friendships/${rootState.common.currentUser.uid}/${friendId}/messages${queryString}`
          )
          .then(response => {
            if (beforeId) {
              commit("setMessages", response.data.concat(state.messages));
            } else {
              commit("setMessages", response.data);
            }
            return response.data;
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    confirmMessages(
      { commit, state, rootState, dispatch },
      { friendId, messageIds }
    ) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .put(
            `friendships/${rootState.common.currentUser.uid}/${friendId}/messages/confirm`,
            messageIds
          )
          .then(() => {
            const friendshipUpdates = [...state.friendshipUpdates];
            state.friendshipUpdates.forEach((friendship, index) => {
              if (messageIds.includes(friendship.lastMessage.id)) {
                friendshipUpdates.splice(index, 1);
              }
            });
            commit("setFriendshipUpdates", friendshipUpdates);
          })
          .catch(e => {
            console.log(e);
            this.dispatch("common/showError", e.message, { root: true });
          });
      });
    },
    createFriendship(
      { commit, state, rootState, dispatch },
      { friendId, message }
    ) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(
            `friendships/${rootState.common.currentUser.uid}/${friendId}?tokenId=${state.messagingTokenId}`,
            message,
            {
              headers: {
                "Content-Type": "text/plain"
              }
            }
          )
          .then(response => {
            let friendships = [...state.friendships];
            friendships.push(response.data);
            commit("setFriendships", friendships);
          });
      });
    },
    fetchFriendship({ commit, state, rootState, dispatch }, friendId) {
      if (state.cachedFriendship) {
        const result = state.cachedFriendship;
        commit("setCachedFriendship", null);
        return result;
      }
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .get(`friendships/${rootState.common.currentUser.uid}/${friendId}`)
          .then(response => {
            return response.data;
          });
      });
    },
    terminateFriendship({ rootState, dispatch }, friendId) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(
            `friendships/${rootState.common.currentUser.uid}/${friendId}/terminate`
          )
          .then(() => dispatch("fetchConversations"));
      });
    },
    reportUser({ rootState, dispatch }, friendId) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(
            `friendships/${rootState.common.currentUser.uid}/${friendId}/report`
          )
          .then(() => dispatch("fetchConversations"));
      });
    },
    sendMessage({ commit, state, rootState, dispatch }, { friendId, message }) {
      return dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .post(
            `friendships/${rootState.common.currentUser.uid}/${friendId}/messages?tokenId=${state.messagingTokenId}`,
            message,
            {
              headers: {
                "Content-Type": "text/plain"
              }
            }
          )
          .then(response => {
            let messages = [...state.messages];
            messages.push(response.data);
            commit("setMessages", messages);
          });
      });
    },
    sendMessagingToken({ commit, rootState, dispatch }, token) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios
          .put(`messaging-tokens/${rootState.common.currentUser.uid}`, token, {
            headers: {
              "Content-Type": "text/plain"
            }
          })
          .then(response => {
            commit("setMessagingTokenId", response.data.id);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    removeMessagingToken({ state, rootState, dispatch }) {
      dispatch("common/withIdToken", {}, { root: true }).then(() => {
        return axios.delete(
          `messaging-tokens/${rootState.common.currentUser.uid}/${state.messagingTokenId}`
        );
      });
    }
  }
};
