import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

// firebase init goes here
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: `${process.env.VUE_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_PROJECT_NUMBER,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: "G-7DCRBFEGT7"
};
firebase.initializeApp(firebaseConfig);

// firebase utils
const auth = firebase.auth();
const currentUser = auth.currentUser;

const messaging = firebase.messaging();

export { auth, messaging, currentUser };
