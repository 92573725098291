import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router);

function loadView(view) {
  return () => import(`@/views/${view}.vue`);
}

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/discover"
    },
    {
      path: "/",
      name: "Landing",
      component: loadView("Landing")
    },
    {
      path: "/verify",
      name: "VerifyEmail",
      component: loadView("VerifyEmail")
    },
    {
      path: "/auth/action",
      name: "AuthAction",
      component: loadView("AuthAction")
    },
    {
      path: "/loader",
      name: "Loader",
      component: loadView("Loader")
    },
    {
      path: "/discover",
      name: "Discover",
      component: loadView("Discover"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/init",
      name: "Initialize",
      component: loadView("InitProfile"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/matches",
      name: "Matches",
      component: loadView("Matches"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/chats/:uid?",
      name: "Chats",
      component: loadView("Chats"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/myself",
      name: "Myself",
      component: loadView("Myself"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/profile/:uid",
      name: "Profile",
      component: loadView("Profile"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: loadView("Settings"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/subscriptions",
      name: "Subscriptions",
      component: loadView("Subscriptions"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/impressum",
      name: "Impressum",
      component: loadView("Impressum")
    },
    {
      path: "/terms",
      name: "Terms of Service",
      component: loadView("Terms")
    },
    {
      path: "/privacy",
      name: "Privacy Policy",
      component: loadView("Privacy")
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;

  if (requiresAuth) {
    if (currentUser) {
      next();
    } else {
      next("/");
    }
  } else {
    if (to.name === "Landing" && currentUser) {
      next("/discover");
    } else {
      next();
    }
  }
});

export default router;
